import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { getImage, GatsbyImage } from 'gatsby-plugin-image';



export default function Section() {
  const data = useStaticQuery(graphql`
    query {
      allMdx(filter: {frontmatter: {reference: {in: ["6-0-0-0", "6-3-6-0", "6-3-0-0"]}}}) {
        edges {
          node {
            frontmatter {
              reference
              slug
              embeddedImagesLocal {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
              thumbnailText
              excerpt
              title
            }
          }
        }
      }
    }
  `)
  

  return (
    <div className="mt-4 px-4 relative bg-gray-100 pt-12 sm:px-6 lg:py-12">
      <div className="relative max-w-7xl mx-auto lg:px-4">
        <div className="text-left">
          <h2 className="text-3xl tracking-tight font-bold text-gray-900 sm:text-3xl"><a href="/droit/">Koudepouce argent</a></h2>
          <p className="mt-3 max-w-7xl mx-auto text-xl text-gray-500 sm:mt-4">
          Dans cette rubrique, vous ne trouverez pas de recette miracle pour gagner de l’argent sur internet ou encore devenir riche du jour au lendemain. Mais vous découvrirez des astuces simples pour faire fructifier votre patrimoine ou économiser votre argent.
          </p>
          <p className="mt-3 max-w-7xl mx-auto text-xl text-gray-500 sm:mt-4">
          Epargne, dépense, budget… Koudepouce vous accompagne pour mieux gérer votre argent au quotidien, en vous donnant accès à des articles thématiques, par exemple sur l’assurance, la banque et l’immobilier.
          </p>
        </div>
        <div className="mt-12 mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
          {data.allMdx.edges.map((page) => (
            <div key={page.node.frontmatter.reference} className="flex lg:flex-col rounded-lg shadow-lg overflow-hidden shadow-cyan-500/30">
              <div className="sm:w-1/3 lg:w-full flex-shrink-0 hover:opacity-75">
                <a href={page.node.frontmatter.slug}>
                  <GatsbyImage 
                    className="h-48 w-full object-cover" 
                    alt={page.node.frontmatter.thumnailText} 
                    image={getImage(page.node.frontmatter.embeddedImagesLocal[0])} 
                  />
                </a>
              </div>
              <div className="sm:w-2/3 lg:w-full flex-1 bg-white p-6 flex flex-col justify-between">
                <div className="flex-1">
                  <p className="text-sm font-medium text-cyan-600">
                    <a href="/argent/" className="hover:underline">
                      Argent
                    </a>
                  </p>
                  <a href={page.node.frontmatter.slug} className="block mt-2 hover:opacity-75">
                    <h3 className="text-xl font-semibold text-gray-900">{page.node.frontmatter.title}</h3>
                    <p className="mt-3 text-base text-gray-500">{page.node.frontmatter.excerpt}</p>
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}