import React from "react"

export default function Section() {
  return (
    <div className="bg-gray-100 relative overflow-hidden">
      {/* Decorative background image and gradient */}
      <div aria-hidden="true" className="absolute inset-0">
        <div className="absolute inset-0 max-w-7xl mx-auto overflow-hidden xl:px-8">
          <img
            src="https://tailwindui.com/img/ecommerce-images/home-page-02-sale-full-width.jpg"
            alt=""
            className="w-full h-full object-center object-cover"
          />
        </div>
        <div className="absolute inset-0 bg-white bg-opacity-75" />
        <div className="absolute inset-0 bg-gradient-to-t from-white via-white" />
      </div>

      {/* Callout */}
      <section
        aria-labelledby="sale-heading"
        className="relative max-w-7xl mx-auto pt-32 px-4 flex flex-col items-center text-center sm:px-6 lg:px-8"
      >
        <div className="max-w-2xl mx-auto lg:max-w-none">
          <h2
            id="sale-heading"
            className="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl lg:text-4xl"
          >
            Trouvez les meilleurs pro simplement, Devis personnalisé.
          </h2>
          <p className="mt-4 max-w-6xl mx-auto text-xl text-gray-600">
          Il n'a jamais été aussi facile de trouver une solution en ligne. En plus des nombreux conseils de Koudepouce, vous pouvez décrire votre besoin et engager le professionnel de votre choix.
          </p>
          <a
            href="/contact/"
            className="mt-6 inline-block w-full bg-amber-600 border border-transparent rounded-md py-3 px-8 font-medium text-white hover:bg-amber-700 sm:w-auto"
          >
            Faire ma demande
          </a>
        </div>
      </section>
    </div>
  )
}
