import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { getImage, GatsbyImage } from 'gatsby-plugin-image';



export default function Section() {
  const data = useStaticQuery(graphql`
    query {
      allMdx(filter: {frontmatter: {reference: {in: ["5-0-0-0", "5-1-0-0", "5-12-0-0"]}}}) {
        edges {
          node {
            frontmatter {
              reference
              slug
              embeddedImagesLocal {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
              thumbnailText
              excerpt
              title
            }
          }
        }
      }
    }
  `)

  return (
    <div className="mt-4 px-4 relative bg-white pt-12 sm:px-6 lg:py-12">
      <div className="relative max-w-7xl mx-auto lg:px-4">
        <div className="text-left">
          <h2 className="text-3xl tracking-tight font-bold text-gray-900 sm:text-3xl"><a href="/sante/">Koudepouce vehicule</a></h2>
          <p className="mt-3 max-w-7xl mx-auto text-xl text-gray-500 sm:mt-4">
          Si on prend en compte l’achat, les frais financiers, l’assurance, l’entretien, les péages et le carburant… en moyenne, un véhicule pèse pour 10% du budget d'un ménage.
          </p>
          <p className="mt-3 max-w-7xl mx-auto text-xl text-gray-500 sm:mt-4">
          Les besoins autour d’un véhicule sont quotidiens pour beaucoup de personnes. Mais ils peuvent aussi être ponctuels, pour des vacances, un déménagement ou un mariage. Démarches, prix, assurance, choix des entreprises autour des véhicules (concessions, loueurs, garagistes, carrossier…). Koudepouce vous aide à faire les meilleurs choix.
          </p>
        </div>
        <div className="mt-12 mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
          {data.allMdx.edges.map((page) => (
            <div key={page.node.frontmatter.reference} className="flex lg:flex-col rounded-lg shadow-lg overflow-hidden shadow-yellow-500/30">
              <div className="sm:w-1/3 lg:w-full flex-shrink-0 hover:opacity-75">
                <a href={page.node.frontmatter.slug}>
                  <GatsbyImage 
                    className="h-48 w-full object-cover" 
                    alt={page.node.frontmatter.thumnailText} 
                    image={getImage(page.node.frontmatter.embeddedImagesLocal[0])} 
                  />
                </a>
              </div>
              <div className="sm:w-2/3 lg:w-full flex-1 bg-white p-6 flex flex-col justify-between">
                <div className="flex-1">
                  <p className="text-sm font-medium text-yellow-600">
                    <a href="/vehicules/" className="hover:underline">
                      Véhicule
                    </a>
                  </p>
                  <a href={page.node.frontmatter.slug} className="block mt-2 hover:opacity-75">
                    <h3 className="text-xl font-semibold text-gray-900">{page.node.frontmatter.title}</h3>
                    <p className="mt-3 text-base text-gray-500">{page.node.frontmatter.excerpt}</p>
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}