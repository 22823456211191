import React from "react"
import { Helmet } from "react-helmet"

import Header from "src/components/headers/header"
import SectionIntro from "src/components/index/sectionIntro"
import SectionMultiCat from "src/components/index/sectionMultiCat"
import SectionCTA from "src/components/index/sectionCTA"
import SectionMaison from "src/components/index/sectionMaison"
import SectionMaisonPage from "src/components/index/sectionMaisonPage"
import SectionDroit from "src/components/index/sectionDroit"
import SectionSante from "src/components/index/sectionSante"
import SectionSantePage from "src/components/index/sectionSantePage"
import SectionArgent from "src/components/index/sectionArgent"
import SectionVehicule from "src/components/index/sectionVehicule"
import SectionVehiculePage from "src/components/index/sectionVehiculePage"
import Footer from "src/components/footers/footer"


export default function index() {

  return (
    <div className="bg-white">
      <Helmet>
        <title>Koudepouce</title>
        <meta name="description" content="Koudepouce simplifie votre vie au quotidien." />
      </Helmet>
      <header className="sticky top-0 z-50 shadow-lg bg-white">
        <Header />
      </header>

      <main className="relative">
        <SectionIntro />
        <SectionMultiCat />
        <SectionCTA />
        <SectionMaison />
        <SectionMaisonPage />
        <SectionDroit />
        <SectionSante />
        <SectionSantePage />
        <SectionArgent />
        <SectionVehicule />
        <SectionVehiculePage />
      </main>

      <footer>
        <Footer />
      </footer>
    </div>
  )
}