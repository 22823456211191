import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { getImage, GatsbyImage } from 'gatsby-plugin-image';



export default function Section() {
  const data = useStaticQuery(graphql`
    query {
      allMdx(filter: {frontmatter: {reference: {in: ["5-3-0-0", "5-2-0-0", "5-4-0-0"]}}}) {
        edges {
          node {
            frontmatter {
              reference
              slug
              embeddedImagesLocal {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
              thumbnailText
              excerpt
              title
            }
          }
        }
      }
    }
  `)

  return (

    <div className="bg-white px-4 pt-8 sm:px-6">
      <div className="max-w-7xl mx-auto pb-12 overflow-hidden lg:px-4">
        <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-5">
          {data.allMdx.edges.map((page) => (
            <a key={page.node.frontmatter.reference} href={page.node.frontmatter.slug} className="flex group text-sm">
              <div className="w-1/3 rounded-lg overflow-hidden bg-gray-100 group-hover:opacity-75">
                <GatsbyImage 
                  className="w-full h-full object-center object-cover"
                  alt={page.node.frontmatter.thumnailText} 
                  image={getImage(page.node.frontmatter.embeddedImagesLocal[0])} 
                />
              </div>
              <h3 className="mt-2 ml-2 font-medium text-lg text-gray-900">{page.node.frontmatter.title}</h3>
            </a>
          ))}
        </div>
      </div>
    </div>

  )
}
