import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { getImage, GatsbyImage } from 'gatsby-plugin-image';


export default function Section() {

  const data = useStaticQuery(graphql`
    query {
      allMdx(filter: {frontmatter: {reference: {in: ["4-0-0-0", "7-0-0-0", "2-0-0-0"]}}}) {
        edges {
          node {
            frontmatter {
              reference
              slug
              embeddedImagesLocal {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
              thumbnailText
              excerpt
              title
            }
          }
        }
      }
    }
  `)


  return (
    
    <div className="bg-white px-4 sm:px-6">
       <div className="mx-auto pt-8 sm:pt-16 lg:max-w-7xl lg:px-4">
        <div className="max-w-3xl">
          <h1 className="mt-2 text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">Koudepouce simplifie votre vie au quotidien</h1>
          <p className="text-xl mt-4 text-gray-500">
            Des solutions en quelques clics. Au meilleur prix.
          </p>
        </div>

        <div className="mx-auto py-4 sm:py-4 lg:py-8 lg:max-w-none">
          <div className="mt-6 space-y-12 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-x-6">
            {data.allMdx.edges.map((page) => (
              <a href={page.node.frontmatter.slug}>
                <div key={page.node.frontmatter.reference} className="group relative">
                  <div className="relative bg-white rounded-lg overflow-hidden group-hover:opacity-75 sm:h-64">
                    <GatsbyImage 
                      className="w-full h-full object-center object-cover" 
                      alt={page.node.frontmatter.thumnailText} 
                      image={getImage(page.node.frontmatter.embeddedImagesLocal[0])} 
                    />
                  </div>
                  <h2 className="mb-4 text-center text-2xl font-semibold text-gray-900">{page.node.frontmatter.title}</h2>
                </div>
              </a>
            ))}
          </div>
        </div>
      </div>
    </div>

  )

}



